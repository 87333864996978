.book-services {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    &__container {
        max-width: 1040px;
        width: 90%
    }

    &__title {
        font-size: 24px;
        font-family: $font-heading;
    }

    .book-services-carousel {
        margin-top: 20px;

        .book-services-card {
            height: fit-content;
            width: 100%;
            min-width: 220px;
        
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0 4px 8px 0 rgba(71, 20, 61, 0.2), 0 6px 20px 0 rgba(71, 20, 61, 0.19);
            border-radius: 10px;
            gap: 10px;
            padding-bottom: 10px;
            transition: box-shadow .3s;
        
            background-color: $primary-color;
            text-decoration: none;
        
            &__image {
                height: 227px;
                width: 100%;
        
                border-radius: 10px 10px 0 0;
                object-fit: cover;
                opacity: 90%;
            }

            &__name {
                background-color: $primary-color;
                color: $white;
                font-size: 20px;
                font-weight: 900;
                font-family: $font-heading;
                text-align: center;
                
                transition: text-shadow 0.3s ease;
            }

            &:hover {
                box-shadow: 0 4px 8px 0 rgba(71, 20, 61, 0.5), 0 6px 20px 0 rgba(71, 20, 61, 0.49);
            }

            &:hover .book-services-card__image {
                opacity: 100%;
            }

            &:hover .book-services-card__name {
                text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
            }
        }
    }
}



