.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    &-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-button-prev, &-button-next {
        @media screen and (max-width: 1000px) {
            display: none !important;
        }

        &::after {
            color: $primary-color;
            font-weight: 900;
        }
        
        &:hover::after {
            color: $secondary-color;
        }
    }

    &-pagination {
        &-bullet, &-progressbar {
            background-color: $primary-color !important;
            opacity: 1 !important;
    
            &-active, &-fill {
                background-color: $secondary-color !important;
            }
        }
    }
}