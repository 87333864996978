.about-us {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    &__container {
        max-width: 1040px;
        width: 90%;
    }

    &__title {
        font-size: 24px;
        font-family: $font-heading;
    }

    &__description {
        margin-top: 20px;
        
        font-size: 16px;
        line-height: 1.5em;
        font-family: $font-text;
        text-align: justify;
    }
}