.top-banner {
    &__home-image {
        object-fit: cover;
        width: 100%;
    }

    &__swiper {
        max-height: 350px !important;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 200px;
        object-fit: fill;
    }
}

.middle-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;

    img {
        max-width: 1040px;
        width: 90%;
        height: 180px;

        border-radius: 10px;
    }
}