// Settings
@import './styles/settings/colors';
@import './styles/settings/fonts';

// Modules
@import './styles/modules/about-us';
@import './styles/modules/banner';
@import './styles/modules/book-services';
@import './styles/modules/contact-us';
@import './styles/modules/footer';
@import './styles/modules/header';
@import './styles/modules/menu';
@import './styles/modules/standards';
@import './styles/modules/swiper';
@import './styles/modules/testimonial';

// Pages
@import './styles/pages/page';

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: $white; 
}

::-webkit-scrollbar-thumb {
    background: $secondary-color; 
}

.app {
    background-color: $background;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}