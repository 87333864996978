.header {
    width: 100%;

    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    padding: 20px;

    background-color: $primary-color;

    &__container {
        max-width: 1170px;
        width: 95%;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        height: 35px;
    }

    &__items {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        color: $white;
        font-weight: 700;

        @media screen and (max-width: 700px) {
            display: none;
        }

        &__open {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position: fixed;
            gap: 20px;
            top: 79px;
            right: 0px;
            width: 80%;
            max-width: 500px;
            height: 100vh;
            box-shadow: 0 5px 15px rgba(0,0,0,0.4);
            background-color: $primary-color;
            padding: 40px 0 0 10px;

            li {
                list-style: none;
                cursor: pointer;
                margin-left: 20px;

                font-size: 20px;
                font-family: $font-heading;
                color: $white;
            }
        }

        li {
            list-style: none;
            cursor: pointer;
            transition: text-shadow 0.3s ease;

            font-size: 20px;
            font-family: $font-heading;

            &:hover {
                text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
            }
        }
    }

    &__mobile {
        @media screen and (min-width: 701px) {
            display: none;
        }

        i {
            color: $white;
            cursor: pointer;
            font-size: 24px;
        }
    }

    .header-auth-modal {
        width: 100%;
        height: 100%;

        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        overflow: auto;

        background-color: rgba(0,0,0,0.4);

        &__container {
            max-height: 500px;
            width: 50%;
            max-width: 700px;

            margin: auto;
            padding: 20px;
            border-radius: 10px;
            gap: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            background-color: $white;

            @media screen and (max-width: 700px) {
                width: 85%;
            }
        }

        i {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;

            color: $primary-color;
            text-align: right;

            &:hover {
                color: $red;
            }

            @media screen and (max-width: 700px) {
                top: 10px;
                right: 10px;
            }
        }

        .log-in {
            width: 50%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;

            @media screen and (min-width: 700px) {
                min-width: 300px;
                max-width: 500px;
            }

            @media screen and (max-width: 700px) {
                width: 100%;
                max-width: 300px;
            }

            &__title {
                color: $primary-color;
                font-size: 20px;
                text-align: center;
                font-family: $font-heading;
            }

            input {
                height: 40px;
                width: 100%;

                padding: 0 10px;
                border: none;
                border-radius: 25px;
                outline: none;

                color: $primary-color;
                text-align: center;
                letter-spacing: 0.05rem;
                font-size: 14px;
                background-color: $input-grey;
                font-family: $font-text;

                &:focus {
                    border: 1px solid $primary-color;
                    font-weight: 600;
                }

                &::placeholder {
                    color: $primary-color;
                }
            }

            &__btn-wrapper {
                display: flex;
                gap: 10px;
            }

            &__btn {
                padding: 10px 20px;
                border-radius: 25px;
                cursor: pointer;
                border: none;
    
                background-color: $primary-color;
                color: $white;
                font-size: 14px;
                font-weight: 900;
                font-family: $font-heading;
    
                &:hover {   
                    color: $green;
                }

                &.resend-btn:hover {
                    color: $secondary-color;
                }
            }
        }

        .sign-up {
            width: 50%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            @media screen and (min-width: 700px) {
                min-width: 300px;
                max-width: 500px;
            }

            @media screen and (max-width: 700px) {
                width: 100%;
                max-width: 300px;
            }

            &__title {
                color: $primary-color;
                font-size: 20px;
                text-align: center;
                font-family: $font-heading;
            }

            input {
                height: 40px;
                width: 100%;

                padding: 0 10px;
                border: none;
                outline: none;
                border-radius: 25px;

                color: $primary-color;
                text-align: center;
                letter-spacing: 0.05rem;
                font-size: 14px;
                background-color: $input-grey;
                font-family: $font-text;

                &:focus {
                    border: 1px solid $primary-color;
                    font-weight: 600;
                }

                &::placeholder {
                    color: $primary-color;
                }
            }

            &__gender {
                width: 100%;

                display: flex;
                justify-content: space-around;

                label {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    color: $primary-color;
                    text-align: center;
                    letter-spacing: 0.05rem;
                    font-size: 14px;
                    font-family: $font-heading;

                    input {
                        height: 20px;
                        width: 20px;

                        accent-color: $primary-color
                    }
                }

                &__separator {
                    background-color: $primary-color;
                    width: 1px;
                }
            }

            &__btn {
                padding: 10px 20px;
                border-radius: 25px;
                cursor: pointer;
                border: none;
    
                background-color: $primary-color;
                color: $white;
                font-size: 14px;
                font-weight: 900;
                font-family: $font-heading;
    
                &:hover {   
                    color: $green;
                }
            }
        }

        .profile  {
            width: 50%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            
            color: $primary-color;
            text-align: center;

            @media screen and (min-width: 700px) {
                min-width: 350px;
                max-width: 500px;
            }

            @media screen and (max-width: 700px) {
                width: 100%;
                max-width: 300px;
            }

            &__name {
                color: $primary-color;
                font-size: 20px;
                text-align: center;
                font-family: $font-heading;
            }

            &__details {
                width: 100%;
                
                display: flex;
                flex-direction: column;
                gap: 10px;

                &__items {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                }

                &__label {
                    flex: 0.3;

                    text-align: left;
                    font-family: $font-text;
                    font-weight: 600;
                    font-size: 16px;
                }

                &__value {
                    flex: 0.7;

                    text-align: right;
                    font-family: $font-text;
                    font-weight: 600;
                    font-size: 16px;
                }
            }

            &__btn {
                padding: 10px 20px;
                border-radius: 25px;
                cursor: pointer;
                border: none;
    
                background-color: $primary-color;
                color: $white;
                font-size: 14px;
                font-weight: 900;
                font-family: $font-heading;
    
                &:hover {   
                    color: $secondary-color;
                }
            }
        }
    }
}