.contact-us {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    &__container {
        max-width: 1040px;
        width: 90%;

        display: flex;
        gap: 20px;
    }

    &__title {
        flex: 0.5;
        font-weight: 900;
        font-size: 20px;
        letter-spacing: 0.15rem;
    }

    &__form {
        flex: 0.5;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        input {
            height: 50px;
            width: 100%;

            padding: 0 10px;
            border: 1px solid $primary-color;
            border-radius: 5px;
            font-weight: 700;
            font-size: 15px;

            color: $primary-color;

            &::placeholder {
                color: $primary-color;
                font-weight: 500;
            }
        }

        &__submit-btn {
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            border: none;

            background-color: $primary-color;
            color: $secondary-color;
            font-weight: 700;
            font-size: 15px;

            &:hover {
                background-color: $secondary-color;
                color: $primary-color;
            }
        }
    }
}