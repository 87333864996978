.standards {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    &__container {
        max-width: 1040px;
        width: 90%;
    }

    &__title {
        font-size: 24px;
        font-family: $font-heading;
    }

    &__cards-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        gap: 20px;

        @media (max-width: 700px) {
            flex-direction: column;
        }

        .standards-card {
            flex: 1;

            &__image {
                width: 100%;
                border-radius: 10px;
            }

            &__title  {
                margin-top: 10px;

                font-size: 20px;
                line-height: 1.5em;
                font-family: $font-heading;
            }

            &__description {
                margin-top: 10px;

                text-align: justify;
                line-height: 1.5em;
                font-family: $font-text;
            }
        }
    }
}