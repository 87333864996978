.footer {
    width: 100%;

    background-color: $black;
    color: $secondary-color;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    &__container {
        max-width: 1040px;
        width: 90%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    &__info-section {
        width: 100%;

        display: flex;
        justify-content: space-evenly;
        gap: 10px;

        @media (max-width: 700px) {
            flex-direction: column;
            gap: 20px;
        }

        &__sub-section {
            flex: 0.33;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;

            &__title {
                color: $white;
                font-size: 20px;
                line-height: 1.5em;
                font-family: $font-heading;
            }

            &__items {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;

                div, a {
                    cursor: pointer;

                    text-align: center;
                    font-size: 16px;
                    text-decoration: none;
                    color: $secondary-color;
                    font-family: $font-heading;
                    line-height: 1.5em;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    &__section-separator {
        height: 2px;
        width: 100%;

        background-color: $white;
    }

    &__social-section {
        width: 100%;

        display: flex;
        gap: 100px;
        align-items: center;
        justify-content: center;

        @media (max-width: 700px) {
            gap: 20px;
            flex-direction: column;
        }

        a {
            color: $white;
            text-decoration: none;
            font-size: 20px;
            font-family: $font-heading;
            line-height: 1.5em;
            transition: text-shadow 0.3s ease;

            &:hover {
                text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
            }
        }
    }
}