@font-face {
    font-family: "montserrat-semibold";
    src: url("../../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "montserrat-light";
    src: url("../../assets/fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 400;
}

$font-heading: "montserrat-semibold", sans-serif;
$font-text: "montserrat-light", sans-serif;