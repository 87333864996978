.testimonial {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    &__container {
        max-width: 1040px;
        width: 90%;
    }

    &__title {
        font-size: 24px;
        font-family: $font-heading;
    }

    .testimonial-carousel {
        margin-top: 20px;

        .swiper {
            &-button-prev, &-button-next {                
                &:hover::after {
                    color: $primary-color;
                }
            }
        }

        .testimonial-card {
            padding: 30px;
            border-radius: 10px;

            color: $black;
            font-weight: 700;

            border: 1px solid $black;
            background-color: rgba(255, 255, 255, 0.45);
            opacity: 50%;
            line-height: 1.5em;

            &:hover {
                opacity: 100%;
            }

            &__name {
                font-size: 20px;
                font-family: $font-heading;
            }

            &__testimonial {
                margin-top: 16px;

                text-align: justify;
                font-family: $font-text;
            }
        }
    }
}