.menu {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    &__container {
        max-width: 1040px;
        width: 90%;
    }

    &__title {
        font-size: 24px;
        font-family: $font-heading;
    }

    &__cards-wrapper {
        margin-top: 20px;

        display: flex;
        flex-direction: column;
        gap: 40px;

        .menu-card {
            display: flex;
            justify-content: center;
            border-radius: 30px;
            box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);

            background-color: $white;

            @media screen and (max-width: 500px) {
                align-items: center;
                flex-direction: column;
            }

            &__image {
                width: 30%;

                border-radius: 30px 0 0 30px;
                object-fit: cover;

                @media screen and (max-width: 500px) {
                    width: 100%;

                    border-radius: 10px 10px 0 0;
                }
            }

            &__details {
                width: 70%;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 10px;
                padding: 35px;

                @media screen and (max-width: 500px) {
                    width: 100%;
                }
            }

            &__header {
                display: flex;
                justify-content: space-between;
            }

            &__title {
                color: $primary-color;
                font-size: 20px;
                font-family: $font-heading;
            }

            &__price-time {
                color: $primary-color;
                font-size: 20px;
                font-family: $font-heading;
            }

            &__body {
                display: flex;
                gap: 10px;
            }

            &__pointers {
                li {
                    text-overflow: ellipsis;
                    line-height: 1.75em;
                    color: $pointer-grey;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: $font-text;
                }
            }

            &__know-more {
                width: fit-content;

                flex: 1;
                cursor: pointer;
                display: flex;
                align-items: flex-end;
                transition: text-shadow 0.3s ease;

                color: $primary-color;
                line-height: 1.75em;
                font-weight: 900;
                font-size: 14px;
                font-family: $font-heading;

                &:hover {
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
                }
            }

            &__book {
                width: fit-content;

                background-color: $primary-color;
                color: $white;
                padding: 7px 10px;
                cursor: pointer;
                border-radius: 25px;

                text-align: center;
                font-size: 14px;
                font-weight: 900;
                font-family: $font-heading;

                &:hover {
                    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
                }
            }
        }
    }

    .menu-modal {
        width: 100%;
        height: 100%;

        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        overflow: auto;

        background-color: rgba(0,0,0,0.4);

        &__container {
            width: 50%;
            max-width: 930px;

            margin: auto;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            background-color: $white;
            font-weight: 700;

            @media screen and (max-width: 700px) {
                width: 85%;
            }
        }

        &__image {
            width: 100%;
            height: 300px;

            object-fit: cover;
            border-radius: 30px 30px 0 0;
        }

        &__details {
            width: 100%;

            display: flex;
            flex-direction: column;
            padding: 15px;
            gap: 10px;

            &__header {
                display: flex;
            }

            &__body {
                max-height: 200px;

                overflow-y: auto;
                padding-right: 10px;

                color: $primary-color;

                &__separator {
                    background-color: $black;
                    height: 1px;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                }
                
                &::-webkit-scrollbar-track {
                    background: $white; 
                }
                
                &::-webkit-scrollbar-thumb {
                    background: $primary-color; 
                }
            }
        }

        &__title {
            color: $primary-color;
            font-size: 20px;
            font-family: $font-heading;
        }

        &__price-time {
            color: $primary-color;
            font-size: 16px;
            font-family: $font-heading;
        }

        &__pointers {
            margin-bottom: 7.5px;
            list-style-position: inside;

            font-family: $font-text;
            line-height: 1.75em;
            color: $pointer-grey;
        }

        &__description {
            margin-top: 7.5px;

            font-family: $font-text;
            line-height: 1.75em;
            text-align: justify;
            color: $pointer-grey;
        }

        &__close-btn {
            width: fit-content;

            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 30px;

            color: $primary-color;

            &:hover {
                color: $red;
            }
        }
    }

    .book-modal {
        width: 100%;
        height: 100%;

        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        overflow: auto;

        background-color: rgba(0,0,0,0.4);

        &__container {
            width: 50%;
            max-width: 930px;

            margin: auto;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            background-color: $white;
            font-weight: 700;

            @media screen and (max-width: 700px) {
                width: 85%;
            }
        }

        &__title {
            color: $primary-color;
            font-size: 20px;
            text-align: center;
            font-family: $font-heading;
        }

        &__form {
            width: 70%;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 15px;

            @media screen and (max-width: 700px) {
                width: 85%;
            }

            &__fieldset {
                width: 100%;

                display: flex;
                gap: 10px;
            }

            &__field {
                width: 100%;
                height: 40px;

                padding: 10px;
                border-radius: 25px;
                border: none;
                outline: none;

                color: $primary-color;
                text-align: center;
                letter-spacing: 0.05rem;
                font-size: 14px;
                background-color: $input-grey;
                font-family: $font-text;

                &:focus {
                    border: 1px solid $primary-color;
                    font-weight: 600;
                }

                &::placeholder {
                    color: $primary-color;
                }
            }

            &__confirm-btn {
                padding: 10px 20px;
                border-radius: 25px;
                cursor: pointer;
                border: none;
    
                background-color: $primary-color;
                color: $white;
                font-size: 14px;
                font-weight: 900;
                font-family: $font-heading;
    
                &:hover {
                    color: $green;
                }
            }
        }

        &__close-btn {
            width: fit-content;

            cursor: pointer;
            position: absolute;
            top: 23px;
            right: 15px;

            color: $primary-color;

            &:hover {
                color: $red;
            }
        }
    }
}